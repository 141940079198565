import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const CharityPageParagraph = ({ paragraphs }) => {
  return (
    <section className="mt-[-55px] mb-[0px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      <LayoutContainer>
        {paragraphs.map((p, i) => (
          <Para paragraph={p.pargraph} image={p.image} key={i} id={i + 1} />
        ))}
      </LayoutContainer>
    </section>
  )
}

export default CharityPageParagraph

const Para = ({ paragraph, image, key, id }) => {
  const pFirst = id % 2 !== 0
  return (
    <div
      key={key}
      className={`mx-auto flex max-w-[1120px] flex-col md:mb-[80px] md:flex-row md:justify-center`}
    >
      <div
        className={`lg:max-[600px] mb-10 md:max-w-[50%] lg:mb-0 custom-charity-page ${
          pFirst ? "md:order-1 md:mr-[75px] lg:mr-[125px]" : "md:order-2"
        }`}
      >
        <StructuredText
          data={paragraph}
          customRules={[
            renderRule(isParagraph, ({ children, key }) => {
              if (children[0].length === 0) return <br />
              return (
                <p
                  key={key}
                  className="font-spartan text-base leading-[25.92px] text-gray-1"
                >
                  {children}
                </p>
              )
            }),
          ]}
        />
      </div>
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt}
        className={`mb-10 md:w-[340px] lg:mb-0 ${
          pFirst ? "md:order-2" : "md:order-1  md:mr-[75px] lg:mr-[125px]"
        }`}
      />
    </div>
  )
}

import React, { Fragment } from "react"

const SectionTitle = ({ children, className }) => {
  return (
    <Fragment>
      <h2
        className={`${className} mb-2 text-center font-spartan text-2xl font-bold`}
        itemProp="headline"
      >
        {children}
      </h2>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        className="mx-auto mb-6 md:mb-8"
      >
        <path
          d="M0.5 12C0.5 5.37258 5.87258 0 12.5 0C19.1274 0 24.5 5.37258 24.5 12V24H12.5C5.87258 24 0.5 18.6274 0.5 12Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="3.40526"
            y1="20.7075"
            x2="21.5106"
            y2="3.9739"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFA903" />
            <stop offset="1" stopColor="#FC4706" />
          </linearGradient>
        </defs>
      </svg>
    </Fragment>
  )
}

export default SectionTitle

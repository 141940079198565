import { useStaticQuery, graphql } from "gatsby"

const useCategoriesData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCharityPage {
        nodes {
          title
          description {
            value
          }
          paragraph {
            pargraph {
              value
            }
            image {
              alt
              gatsbyImageData
            }
          }
          backgroundImage {
            alt
            gatsbyImageData
          }
          foregroundImage {
            alt
            gatsbyImageData(imgixParams: { w: "276", h: "528" })
          }
        }
      }
    }
  `)
  return data.allDatoCmsCharityPage.nodes[0]
}

export default useCategoriesData

import React from "react"
import { SwiperSlide } from "swiper/react"
import {
  useViewportScroll,
  motion,
  useTransform,
  useSpring,
} from "framer-motion"

import { useFeaturedCharitiesData } from "../../system/DatoCms/HomepageData"
import { Container, CharityCard } from "../UI"
import SectionTitle from "../SectionTitle"
import ReactCarousel from "../ReactCarousel"
// import { ModalFeaturedCharityFrame } from "../Modals"

const FeaturedCharitiesSection = () => {
  // const [selectedSlideImage, setSelectedSlideImage] = useState(null)
  const { featuredCharitySectionTitle, featuredCharitySlides } =
    useFeaturedCharitiesData()
  // const modalRef = useRef()
  const { scrollY } = useViewportScroll()
  const y = useSpring(useTransform(scrollY, [0, 1500], [0, 200]), {
    damping: 25,
  })

  // const selectedSlideImageHandler = image => {
  //   setSelectedSlideImage(image)
  //   modalRef.current.openModal()
  // }

  return (
    <section className="relative mt-[128px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      <Container>
        <SectionTitle>{featuredCharitySectionTitle}</SectionTitle>
      </Container>
      <div className="mx-auto max-w-screen-2xl overflow-hidden px-6 pt-7 pb-10 md:px-0 md:pl-6 lg:px-20 xl:px-48">
        <ReactCarousel isFeatured>
          {featuredCharitySlides.map(slide => (
            <SwiperSlide key={slide.id}>
              <CharityCard
                {...slide}
                // onClick={() =>
                //   selectedSlideImageHandler(slide.featuredCharityQrCode)
                // }
              />
            </SwiperSlide>
          ))}
        </ReactCarousel>
      </div>
      <motion.img
        src="https://thetoucan.app/images/shape_thin.svg"
        alt="Toucan Svg"
        className="pointer-events-none absolute top-52 -left-72 z-[-1] hidden h-[684px] w-[684px] lg:inline-block"
        style={{ y }}
      />
      {/*<ModalFeaturedCharityFrame*/}
      {/*  ref={modalRef}*/}
      {/*  charitiesQrCode={selectedSlideImage}*/}
      {/*/>*/}
    </section>
  )
}

export default FeaturedCharitiesSection

import React, { Fragment } from "react"

import { CharityHero } from "../Hero"
import FeaturedCharitiesSection from "../FeaturedCharitiesSection"
import CharityPageParagraph from "../CharityPageParagraph"
// import CharitForm from "../CharityForm"
import { useCharityPageData } from "../../system/DatoCms/CharityPageData"

const CharitiesNew = () => {
  const { title, description, foregroundImage, paragraph } =
    useCharityPageData()
  return (
    <Fragment>
      <CharityHero
        title={title}
        heroPhones={foregroundImage}
        description={description}
      />
      <CharityPageParagraph paragraphs={paragraph} />
      {/* <CharitForm /> */}
      <FeaturedCharitiesSection />
    </Fragment>
  )
}

export default CharitiesNew

import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Main } from "../components/UI"
import Charities from "../components/CharitiesPage"

const CharitiesPage = () => {
  const canonicalUrl = `https://thetoucan.app/charities/`

  return (
    <Layout isHomePage>
      <Seo title="Charities" canonicalUrl={canonicalUrl} />
      <Main className="">
        <Charities />
      </Main>
    </Layout>
  )
}

export default CharitiesPage
